<template>
  <b-row class="justify-content-center">
    <b-col md="6" xl="6">
      <b-card title="Upload file syarat dan ketentuan umum">
        <b-overlay :show="loading" rounded="sm">
          <b-form-group label-for="file">
            <b-form-file
              v-model="form.file"
              placeholder="Pilih file"
              accept=".pdf"
              ref="file"
              id="file"
            />
          </b-form-group>
          <b-button
            v-if="form.file !== null"
            block
            class="mt-2"
            variant="success"
            @click="onSend"
            >Kirim</b-button
          >
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BOverlay,
  BFormFile,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BOverlay,
    BFormFile,
  },
  data() {
    return {
      loading: false,
      hasImage: false,
      merchantId: null,
      businessType: null,
      state: {
        file: null,
      },
      form: {
        file: null,
      },
    };
  },
  created() {
    this.merchantId = this.$store.state.app.merchantId;

    if (this.$store.state.app.merchantId === null) {
      this.merchantId = localStorage.getItem("merchantId", this.merchantId);
    }

    this.businessType = this.$store.state.app.businessType;

    if (this.$store.state.app.businessType === null) {
      this.businessType = localStorage.getItem(
        "businessType",
        this.businessType
      );
    }
  },
  methods: {
    validation() {
      if (this.form.file == null) {
        this.$bvToast.toast("file tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      } else {
        return true;
      }
    },
    onSend() {
      if (this.validation()) {
        this.loading = true;

        let formData = new FormData();
        formData.append("file", this.form.file);

        this.$http
          .post("/merchants/" + this.merchantId + "/file/add", formData)
          .then((res) => {
            this.loading = false;
            this.$bvToast.toast("Berhasil", {
              title: "Berhasil",
              variant: "success",
              solid: true,
              autoHideDelay: 1000,
            });
            this.$store.state.app.merchantId = this.merchantId;
            localStorage.setItem("merchantId", this.merchantId);
            if (this.businessType === 2) {
              this.$router.push("/merchant/add/npwp");
            } else {
              this.$router.push("/merchant");
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            this.$bvToast.toast(err.response.data.message, {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 1000,
            });
          });
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
  },
};
</script>
